
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
import _ from "lodash";
// let loadingEl: any;

export default defineComponent({
  name: "Candidate",
  async created() {
    if (!localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }

    this.sectionListStepTwo = [];

    this.sectionListStepTwo.push({
      title: "Values_and_Traits",
      description: [{ class: "", label: "Values_and_traits_influence" }],
      description_end: "Determine_values_traits",
      timeDefault: 5,
      keyValue: "values_and_traits",
    });
    this.sectionListStepTwo.push({
      title: "Culture_Profile",
      description: [
        { class: "", label: "Organizational_culture_is_driven" },
        { class: "fw-700", label: "Clan_Create_Collaborate_and" },
        { class: "", label: "across_six_dimensions_of" },
      ],
      description_end: "Determine_culture_profile",
      timeDefault: 5,
      keyValue: "OAIC_archetypes",
    });
    if (this.HUMAN_SKILLS_ENABLED) {
      this.sectionListStepTwo.push({
        title: "Human_Skills",
        description: [{ class: "", label: "Human_skills_soft_skills" }],
        description_end: "Evaluate_human_skills",
        timeDefault: 10,
        keyValue: "human_skills",
      });
    }
    this.sectionListStepTwo.push({
      title: "Acceptable_Behaviors",
      description: [{ class: "", label: "Behaviors_are_considered_toxic" }],
      description_end: "Determine_expected_behaviors_and",
      timeDefault: 5,
      keyValue: "acceptable_behaviors",
    });
    this.sectionListStepTwo.push({
      title: "Work_Style_Identifier",
      description: [
        { class: "", label: "Identify_a_candidate_work_style" },
        { class: "fw-700", label: "Product_Process_or_People" },
        { class: "", label: "Understanding_the_inclination_for" },
      ],
      description_end: "",
      timeDefault: 5,
      keyValue: "work_style_identifier",
    });

    const params = this.$router.currentRoute.value.params;
    const routeId = params.routeId || "";
    const isNew = routeId.length == 0;

    let doc;
    // console.log(isNew, "isNew");
    if (isNew) {
      let initFormDetails;
      if (this.RELOAD_TO_EDIT_ENABLED) {
        await this.$store.dispatch("assessmentTemplate/getInitFormDetails");
        initFormDetails =
          this.$store.getters["assessmentTemplate/initFormDetails"];
      }
      // console.log(initFormDetails, "initFormDetails");
      doc = await this.$store.dispatch(
        "assessmentTemplate/new",
        initFormDetails
      );
    } else {
      doc = await this.$store.dispatch(
        "assessmentTemplate/edit",
        {
          routeId: routeId,
        },
        { root: true }
      );
    }

    if (!doc) {
      console.log("doc is undefiend");
      return;
    }

    // console.log(doc.key, "doc.key");

    const values = doc.val();
    this.form.name = values.form_name;
    this.form.intendedfor = values.objective;
    const sections = values.sections || [];
    if (Array.isArray(values.languages_required)) {
      for (const i in values.languages_required) {
        this.form.langRequired.push(values.languages_required[i]);
      }
    }

    // advanced options
    if (isNew) {
      this.form.extraTime = "";
      this.form.sharingResults = false;
      this.form.openEndedQuestion = true;
    } else {
      this.form.extraTime = values.extra_time || "";
      this.form.sharingResults = values.auto_send_result || false;
      this.form.openEndedQuestion = values.open_ended_question || false;

      // console.log(values, "values");
      if (values.redirect_url) {
        this.form.redirectUrl = values.redirect_url;
      }
    }

    // flags for edit mode
    this.isNew = isNew;
    this.isStartedTest = values.is_started_test || false;

    // @fixed
    // this.isStartedTest = false;

    if (isNew) {
      for (const i in sections) {
        this._onClickSection(sections[i]);
      }
    } else {
      this.form.sections.values_and_traits = false;
      this.form.sections.OAIC_archetypes = false;
      this.form.sections.human_skills = false;
      this.form.sections.acceptable_behaviors = false;
      this.form.sections.work_style_identifier = false;
      for (const i in sections) {
        this._onClickSection(sections[i]);
      }
    }

    if (!this.HUMAN_SKILLS_ENABLED) {
      this.form.sections.human_skills = false;
    }

    const sectionNames = [
      "values_and_traits",
      "oaic_archetypes",
      "human_skills",
      "acceptable_behaviors",
      "work_style_identifier",
    ];
    for (const key in sectionNames) {
      const sectionName = sectionNames[key].toLowerCase();
      if (Object.hasOwnProperty.call(values, sectionName)) {
        if (sectionName == "values_and_traits") {
          this.form.setupSections.values_and_traits = true;
        }
        if (sectionName == "oaic_archetypes") {
          this.form.setupSections.OAIC_archetypes = true;
        }
        if (sectionName == "human_skills") {
          this.form.setupSections.human_skills = true;
        }
        if (sectionName == "acceptable_behaviors") {
          this.form.setupSections.acceptable_behaviors = true;
        }
        if (sectionName == "work_style_identifier") {
          this.form.setupSections.work_style_identifier = true;
        }
      }
    }

    const step = String(this.$router.currentRoute.value.params.step);
    const pos = this.stepNames.indexOf(step);
    this.step = pos !== -1 ? pos + 1 : 1;

    // console.log(step, "step");
    // console.log(pos, "pos");
    // console.log(this.step, "this.step");

    if (this.step == 3) {
      if (
        this.form.sections.values_and_traits ||
        this.form.sections.OAIC_archetypes
      ) {
        await this.$store.dispatch(
          "aiAssistedSetup/initDefaultCoreValues",
          null,
          {
            root: true,
          }
        );
        const defaultCoreValues =
          this.$store.getters["aiAssistedSetup/defaultCoreValues"] || [];
        for (const i in defaultCoreValues) {
          this.form.coreValue.push(defaultCoreValues[i]);
        }
      }
    }
  },
  watch: {
    error(value) {
      console.error(value);
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     loadingEl.close();
    //   }
    // },
    valuesAndTraitsLoading(value) {
      // console.log(`valuesAndTraitsLoading(${value})`);
      this.setupByAILoadingStatus.values_and_traits = value;
      if (value) {
        this.setupByAIStatus.values_and_traits = true;
      }
    },
    cultureProfileLoading(value) {
      // console.log(`cultureProfileLoading(${value})`);
      this.setupByAILoadingStatus.OAIC_archetypes = value;
      if (value) {
        this.setupByAIStatus.OAIC_archetypes = true;
      }
    },
  },
  computed: {
    extraTimeAdd() {
      let extime = "";
      extime = this.form.extraTime;
      return Number(extime);
    },
    btnNexstepOne() {
      if (this.form.name && this.form.intendedfor) {
        if (this.LANGUAGES_REQUIRED_ENABLED) {
          if (!this.form.langRequired.length) return false;
        }
        return true;
      } else {
        return false;
      }
    },
    btnNexstepTwo() {
      const value =
        this.form.sections.values_and_traits ||
        this.form.sections.OAIC_archetypes ||
        this.form.sections.human_skills ||
        this.form.sections.acceptable_behaviors ||
        this.form.sections.work_style_identifier;
      if (value) {
        return true;
      } else {
        return false;
      }
    },
    btnNexstepThree() {
      let validate = true;
      if (this.form.sections.values_and_traits) {
        if (!this.hasValuesAndTraits) {
          validate = false;
        }
      }
      if (this.form.sections.OAIC_archetypes) {
        if (!this.hasOaicArchetypes) {
          validate = false;
        }
      }
      if (this.form.sections.acceptable_behaviors) {
        if (!this.hasAcceptableBehaviors) {
          validate = false;
        }
      }
      return validate;
    },
    formId(): any {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values(): any {
      return this.$store.getters["assessmentTemplate/values"];
    },
    error(): any {
      return this.$store.getters["auth/error"];
    },
    loading(): any {
      return this.$store.getters["assessmentTemplate/loading"];
    },
    formName(): any {
      return this.$store.getters["assessmentTemplate/formName"];
    },
    hasValuesAndTraits(): any {
      return this.$store.getters["assessmentTemplate/hasValuesAndTraits"];
    },
    hasOaicArchetypes(): any {
      return this.$store.getters["assessmentTemplate/hasOaicArchetypes"];
    },
    hasAcceptableBehaviors(): any {
      return this.$store.getters["assessmentTemplate/hasAcceptableBehaviors"];
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
    valuesAndTraitsLoading(): boolean {
      return this.$store.getters["aiAssistedSetup/valuesAndTraitsLoading"];
    },
    cultureProfileLoading(): boolean {
      return this.$store.getters["aiAssistedSetup/cultureProfileLoading"];
    },
    coreValueEnabled(): boolean {
      if (!this.form.coreValue.length) return false;

      if (this.form.coreValueSearched) {
        const currentSearch = this.form.coreValue.join(", ");
        const lastSearch = this.form.lastCoreValue.join(", ");
        if (currentSearch == lastSearch) return false;
      }

      return true;
    },
  },
  data() {
    return {
      isNew: false,
      isStartedTest: false,
      step: 1, // 1
      publishSubmitStatus: true,
      intendedforOptions: [
        "Hiring_candidates",
        "New_Hires",
        "Current_team_members",
      ],
      LANGUAGES_REQUIRED_ENABLED: true,
      HUMAN_SKILLS_ENABLED: true,
      AI_ASSISTED_SETUP: false,
      BACK_STEP_ENABLED: true,
      RELOAD_TO_EDIT_ENABLED: true,
      stepNames: ["name", "select", "setup", "review"],
      form: {
        name: "",
        intendedfor: "",
        sharingResults: true,
        openEndedQuestion: true,
        extraTime: "",
        langRequired: [] as any,
        coreValue: [] as any,
        coreValueSearched: false as any,
        lastCoreValue: [] as any,
        redirectUrl: "",
        sections: {
          values_and_traits: false,
          OAIC_archetypes: false,
          acceptable_behaviors: false,
          work_style_identifier: false,
          human_skills: false,
        },
        setupSections: {
          values_and_traits: false,
          OAIC_archetypes: false,
          acceptable_behaviors: false,
          work_style_identifier: false,
          human_skills: false,
        },
      },
      validateForm: {
        name: false,
        intendedfor: false,
        langRequired: false,
        setupSections: {
          values_and_traits: false,
          OAIC_archetypes: false,
          acceptable_behaviors: false,
          work_style_identifier: false,
        },
      },
      langRequired: [
        {
          value: "English",
          label: "English",
        },
        {
          value: "Thai",
          label: "Thai - ไทย",
        },
      ],
      sectionListStepTwo: [
        // {
        //   title: "Values_and_Traits",
        //   description: [{ class: "", label: "Values_and_traits_influence" }],
        //   description_end: "Determine_values_traits",
        //   timeDefault: 5,
        //   keyValue: "values_and_traits",
        // },
        // {
        //   title: "Culture_Profile",
        //   description: [
        //     { class: "", label: "Organizational_culture_is_driven" },
        //     { class: "fw-700", label: "Clan_Create_Collaborate_and" },
        //     { class: "", label: "across_six_dimensions_of" },
        //   ],
        //   description_end: "Determine_culture_profile",
        //   timeDefault: 5,
        //   keyValue: "OAIC_archetypes",
        // },
        // {
        //   title: "Human_Skills",
        //   description: [{ class: "", label: "Human_skills_soft_skills" }],
        //   description_end: "Evaluate_human_skills",
        //   timeDefault: 10,
        //   keyValue: "human_skills",
        // },
        // {
        //   title: "Acceptable_Behaviors",
        //   description: [{ class: "", label: "Behaviors_are_considered_toxic" }],
        //   description_end: "Determine_expected_behaviors_and",
        //   timeDefault: 5,
        //   keyValue: "acceptable_behaviors",
        // },
        // {
        //   title: "Work_Style_Identifier",
        //   description: [
        //     { class: "", label: "Identify_a_candidate_work_style" },
        //     { class: "fw-700", label: "Product_Process_or_People" },
        //     { class: "", label: "Understanding_the_inclination_for" },
        //   ],
        //   description_end: "",
        //   timeDefault: 5,
        //   keyValue: "work_style_identifier",
        // },
      ] as any,
      coreValueTags: [
        {
          label: "Purpose-driven",
          id: "purpose-driven",
        },
        {
          label: "Human-centric",
          id: "human-centric",
        },
        {
          label: "Disruptive thinking",
          id: "disruptive_thinking",
        },
        {
          label: "Customer obsession",
          id: "customer-obsession",
        },
        {
          label: "Bold experimentation",
          id: "bold-experimentation",
        },
        {
          label: "Continuous learning",
          id: "continuous_learning",
        },
        {
          label: "Radical transparency",
          id: "radical_transparency",
        },
        {
          label: "Growth-mindset",
          id: "growth-mindset",
        },
      ],
      setupByAILoadingStatus: {
        values_and_traits: false,
        OAIC_archetypes: false,
        // acceptable_behaviors: false,
        // work_style_identifier: false,
        // human_skills: false,
      },
      setupByAIStatus: {
        values_and_traits: false,
        OAIC_archetypes: false,
        // acceptable_behaviors: false,
        // work_style_identifier: false,
        // human_skills: false,
      },
    };
  },
  methods: {
    setupByAI() {
      // if (
      //   this.setupByAILoadingStatus.values_and_traits ||
      //   this.setupByAILoadingStatus.OAIC_archetypes
      // ) {
      //   return;
      // }

      // Values and Traits
      if (this.form.sections.values_and_traits) {
        this.setupByAILoadingStatus.values_and_traits = true;
        this.$store.dispatch(
          "aiAssistedSetup/setup",
          { section: "values", values: this.form.coreValue },
          {
            root: true,
          }
        );
        this.$store.dispatch(
          "aiAssistedSetup/setup",
          { section: "traits", values: this.form.coreValue },
          {
            root: true,
          }
        );
      }

      // Culture Profile (OAIC Archtypes)
      if (this.form.sections.OAIC_archetypes) {
        this.setupByAILoadingStatus.OAIC_archetypes = true;
        this.$store.dispatch(
          "aiAssistedSetup/setup",
          { section: "culture_profile", values: this.form.coreValue },
          {
            root: true,
          }
        );
      }

      // Save core values
      this.$store.dispatch(
        "assessmentTemplate/saveCoreValues",
        this.form.coreValue,
        { root: true }
      );

      // Save new core values for use it in the future by latest core values
      this.$store.dispatch(
        "coreValuesAnalyzer/saveValues",
        this.form.coreValue,
        {
          root: true,
        }
      );

      this.form.coreValueSearched = true;
      this.form.lastCoreValue = _.clone(this.form.coreValue);
    },
    proceedWithManualSetup() {
      const top_of_setup_card = (this.$refs as any).top_of_setup_card;
      if (top_of_setup_card) {
        top_of_setup_card.scrollIntoView({ behavior: "smooth" });
      }
    },
    backStep() {
      if (!this.BACK_STEP_ENABLED) {
        this.$router.go(-1);
        return;
      }
      if (this.step > 1) {
        this.step--;
        this.$router.push({
          params: { step: this.stepNames[this.step - 1] },
        });
      } else {
        this.$router.go(-1);
      }
    },
    gotoStep(step: number) {
      if (!this.RELOAD_TO_EDIT_ENABLED) return;
      if (this.step > step) {
        this.step = step;
      }

      // TODO: jump to forward
    },
    btnNextStepCls(step: number) {
      if (!this.RELOAD_TO_EDIT_ENABLED) return;
      let cls = "";
      if (this.step > step) {
        cls += " box-step-clickable";
      }
      return cls;
    },
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    oneNextstep() {
      this.validateForm.name = false;
      if (!this.form.name) {
        this.validateForm.name = true;
      }
      this.validateForm.intendedfor = false;
      if (!this.form.intendedfor) {
        this.validateForm.intendedfor = true;
      }
      if (!this.validateForm.intendedfor && !this.validateForm.name) {
        // update form name and objective
        const formDetailsDto = {
          _id: this.formId,
          form_name: this.form.name,
          objective: this.form.intendedfor,
          languagesRequired: this.form.langRequired,
          callback: () => {
            // console.log("noop...");
          },
        };
        this.$store.dispatch(
          "assessmentTemplate/saveFormDetails",
          formDetailsDto,
          { root: true }
        );

        this.step = 2;
        this.$router.push({
          params: { step: "select" },
        });
      }
    },
    async oneNextstepStartedTest() {
      await this.oneNextstep();
      this.step = 1;
      this.$router.push({
        params: { step: "name" },
      });
      await this.publishSubmit();
    },
    async twoNextstep() {
      // console.log("twoNextstep()");
      const value =
        this.form.sections.values_and_traits ||
        this.form.sections.OAIC_archetypes ||
        this.form.sections.human_skills ||
        this.form.sections.acceptable_behaviors ||
        this.form.sections.work_style_identifier;
      if (value) {
        if (!this.form.coreValue.length) {
          await this.$store.dispatch(
            "aiAssistedSetup/initDefaultCoreValues",
            null,
            {
              root: true,
            }
          );
          const defaultCoreValues =
            this.$store.getters["aiAssistedSetup/defaultCoreValues"] || [];
          for (const i in defaultCoreValues) {
            this.form.coreValue.push(defaultCoreValues[i]);
          }
        }

        this.step = 3;
        this.$router.push({
          params: { step: "setup" },
        });
      } else {
        ElMessage({
          message: (this as any).$t("popup.message.message_14w"),
          type: "warning",
        });
      }
    },
    setUpValuesAndTraitsNewTab(formId: string) {
      // console.log("setUpValuesAndTraitsNewTab()");
      let routeData = this.$router.resolve({
        name: "AssessmentsCreateSetUpTaluesAndTraits",
        query: { id: formId },
      });
      if (!this.form.setupSections.values_and_traits) {
        window.open(routeData.href, "_blank");
      } else {
        ElMessageBox.confirm(
          (this as any).$t("popup.question.question_box_4.question"),
          (this as any).$t("popup.question.question_box_4.title"),
          {
            confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
            cancelButtonText: (this as any).$t("popup.optional.Cancel"),
            type: "warning",
          }
        ).then(() => {
          window.open(routeData.href, "_blank");
        });
      }
      this.form.setupSections.values_and_traits = true;
    },
    setUpOAIC_ArchetypesNewTab(formId: string) {
      let routeData = this.$router.resolve({
        name: "SetupOAICArchetypes",
        query: { id: formId },
      });
      if (!this.form.setupSections.OAIC_archetypes) {
        window.open(routeData.href, "_blank");
      } else {
        ElMessageBox.confirm(
          (this as any).$t("popup.question.question_box_4.question"),
          (this as any).$t("popup.question.question_box_4.title"),
          {
            confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
            cancelButtonText: (this as any).$t("popup.optional.Cancel"),
            type: "warning",
          }
        ).then(() => {
          window.open(routeData.href, "_blank");
        });
      }
      this.form.setupSections.OAIC_archetypes = true;
    },
    setUpAcceptableBehaviorsNewTab(formId: string) {
      let routeData = this.$router.resolve({
        name: "SetupAcceptableBehaviors",
        query: { id: formId },
      });
      if (!this.form.setupSections.acceptable_behaviors) {
        window.open(routeData.href, "_blank");
      } else {
        ElMessageBox.confirm(
          (this as any).$t("popup.question.question_box_4.question"),
          (this as any).$t("popup.question.question_box_4.title"),
          {
            confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
            cancelButtonText: (this as any).$t("popup.optional.Cancel"),
            type: "warning",
          }
        ).then(() => {
          window.open(routeData.href, "_blank");
        });
      }
      this.form.setupSections.acceptable_behaviors = true;
    },
    threeNextstep() {
      if (this.btnNexstepThree) {
        this.step = 4;
        this.$router.push({
          params: { step: "review" },
        });
      } else {
        ElMessage({
          message: (this as any).$t("popup.message.message_15w"),
          type: "warning",
        });
      }
    },
    submitEventPublish() {
      this.publishSubmitStatus = false;
    },
    async publishSubmit() {
      // console.log("publishSubmit()");
      this.submitEventPublish();
      const isNew = this.$store.getters["assessmentTemplate/isNew"];
      const method = isNew
        ? "createAssessmentTemplateFromTempForm"
        : "updateAssessmentTemplateFromTempForm";
      const callback = () => {
        this.publishSubmitStatus = true;
        this.$router.replace("/assessments");
      };
      const params = this.$router.currentRoute.value.params;
      const routeId = params.routeId || "";
      // console.log(this.form, "this.form");
      // if (routeId) return;
      await this.$store.dispatch(
        "assessmentTemplate/" + method,
        {
          formId: this.formId,
          routeId: routeId,
          callback: callback,
          extraTime: this.form.extraTime,
          autoSendResult: this.form.sharingResults,
          openEndedQuestion: this.form.openEndedQuestion,
          redirectUrl: this.form.redirectUrl,
        },
        { root: true }
      );
    },
    onChangeFormName(event: any) {
      const value = event.target.value;
      this.$store.commit("assessmentTemplate/formName", value, { root: true });
    },
    checkingItIsURL(url: string) {
      if (url === "") return "";
      var expression =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      const regex = new RegExp(expression);
      if (url.match(regex)) {
        return "validate-false";
      }
      return "validate-true";
    },
    onChangeObjective(event: any) {
      const value = event.target.value;
      this.$store.commit("assessmentTemplate/objective", value, { root: true });
    },
    _onClickSection(name: string) {
      // Can't dynamic assign property value
      switch (name) {
        case "values_and_traits":
          this.form.sections.values_and_traits =
            !this.form.sections.values_and_traits;
          break;
        case "OAIC_archetypes":
          this.form.sections.OAIC_archetypes =
            !this.form.sections.OAIC_archetypes;
          break;
        case "human_skills":
          this.form.sections.human_skills = !this.form.sections.human_skills;
          break;
        case "acceptable_behaviors":
          this.form.sections.acceptable_behaviors =
            !this.form.sections.acceptable_behaviors;
          break;
        case "work_style_identifier":
          this.form.sections.work_style_identifier =
            !this.form.sections.work_style_identifier;
          break;
      }
    },
    onClickSection(name: string) {
      this._onClickSection(name);
      this.$store.dispatch("assessmentTemplate/toggleSection", name);
    },
  },
});
